@font-face {
  font-family: 'SF UI Text Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Text Regular'), url('SFUIText-Regular.woff') format('woff');
}


/*DARK KNIGHT THEME*/

body {
  background: #000;
  color: #fff;
  margin: 0;
  font-family: "SF UI Text Regular", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17.28px;
}

body .container {
  padding: .3rem;
}

h1,h2,h3,h4,h5 {
  color: #161d25;
}

a, a:active, a:visited, .btn.btn-link, .btn.btn-link:active, .btn.btn-link:hover {
  color: #fff;
}

.column {
  padding: .3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.navbar {
  padding: 5px 10px 0 10px;
  /*order-top: #5C6AC4 3px solid;*/
}

.btn-active, .btn:active, .btn:focus, .btn:hover {
  border-bottom: 2px solid;
  background: none;
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
}

.card, .ListItem {
  background-color: rgba(52, 73, 99, 1) !important;
  border-radius: 0.1rem;
}

.ListItem {
  border-bottom: solid 1px rgba(255,255,255,.1) !important;
  padding: 0 !important;
}

.card {
  border: 0;
  box-shadow: none;
}

.panel {
  background-color: #615631;
  border: transparent;
  height: 100%;
}

.panel .ListItem {
  background-color: transparent !important;
  border-bottom: solid 1px rgba(255,255,255,.1) !important;
}

.text-gray {
  color: #bcc3ce!important;
}

.btn.btn-primary, .btn.btn-primary:active, .btn.btn-primary:hover {
  background-color: rgba(255,255,255,.1);
  border: transparent;
}

.btn.btn-primary:focus, .btn.btn-primary:hover {
  background-color: rgba(255,255,255,.3);
  border: transparent;
}

.btn.btn-link {
  color: #d2d5d9;
}



/*WHITE THEME */

/*body {*/
/*  background: #F4F6F8;*/
/*  color: #48427c;*/
/*  margin: 0;*/
/*  font-family: "SF UI Text Regular", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  font-size: 17.28px;*/
/*}*/

/*body .container {*/
/*  padding:0;*/
/*}*/

/*h1,h2,h3,h4,h5 {*/
/*  color: #161d25;*/
/*}*/

/*.column {*/
/*  padding: .4rem;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*  monospace;*/
/*}*/

/*.navbar {*/
/*  padding: 5px 10px 0 10px;*/
/*  !*order-top: #5C6AC4 3px solid;*!*/
/*}*/

/*.btn-active {*/
/*  border-bottom: 2px solid;*/
/*  background: none;*/
/*  border-top: transparent;*/
/*  border-right: transparent;*/
/*  border-left: transparent;*/
/*}*/

/*.btn.btn-primary {*/
/*  background: linear-gradient(rgb(255, 255, 255), rgb(249, 250, 251));*/
/*  border-radius: 3px;*/
/*  box-shadow: rgba(22, 29, 37, 0.05) 0px 1px 0px 0px;*/
/*  line-height: 1;*/
/*  color: rgb(33, 43, 54);*/
/*  text-align: center;*/
/*  cursor: pointer;*/
/*  user-select: none;*/
/*  transition-property: background, border, box-shadow;*/
/*  transition-duration: var(--p-override-none,0.2s);*/
/*  transition-timing-function: var(--p-override-none,cubic-bezier(0.64,0,0.35,1));*/
/*  -webkit-tap-highlight-color: transparent;*/
/*  border-width: 1px;*/
/*  border-style: solid;*/
/*  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);*/
/*}*/

/*.card {*/
/*  border-radius: 0.2rem;*/
/*  border: 0;*/
/*  box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);*/
/*}*/


/*.panel {*/
/*  border-radius: 0.2rem;*/
/*  background: #fff;*/
/*  box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);*/
/*  border: .05rem solid #dadee4;*/
/*  height: 100%;*/
/*}*/

/*.text-gray {*/
/*  color: #bcc3ce!important;*/
/*}*/
