.List {
  flex: 1;
  width: 100%;
  height: 100%;
}

.ListItem {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  min-height: 53px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px;
  border-bottom: .05rem solid #f6f6fa;
  display: grid;
}

.BouncingListItem {
  transition: transform 0.5s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  min-height: 53px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: .05rem solid #f6f6fa;
  display: grid;
}

/* .Items2 {
  grid-template-columns: 1fr 1fr;
}

.Items3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.Items4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
} */

.BackgroundSwipeLeft {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;

  padding-right: 16px;
  color: white;
  background-color: #e85600;
  box-sizing: border-box;
}

.BackgroundSwipeLeftDisabled {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;

  padding-right: 16px;
  color: white;
  background-color: #7e7e7e;
  box-sizing: border-box;
}

.BackgroundSwipeRight {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;

  padding-left: 16px;
  color: white;
  background-color: #32b643;
  box-sizing: border-box;
}

.BackgroundSwipeRightDisabled {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;

  padding-left: 16px;
  color: white;
  background-color: #7e7e7e;
  box-sizing: border-box;
}

.Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
}
